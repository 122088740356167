<template>
  <!-- 库房材料变化明细表 -->
  <el-card id="printContent" :body-style="{ padding: '5px' }">
    <div slot="header" style="height:20px;line-height:20px;text-align:center;">
      <div style="float:left">
        <el-button type="primary" size="small" @click="printContent()">打印</el-button>
        <download-excel style="display:inline-block" :data="tableData" :fields="json_fields" :header="title" :footer="footer" :name="title + '.xlsx'">
          <el-button type="success" size="small">导出</el-button>
        </download-excel>
      </div>
      <span style="font-weight:bold;font-size: 20px;color: blue;">{{ thedate }} 各分公司库房库存报表</span>
      <div style="float:right">
        <el-date-picker
          size="small"
          type="date"
          :picker-options="pickerOptions"
          value-format="yyyy-MM-dd"
          placeholder="选择日期"
          v-model="thedate"
          style="width: 150px;"
          @change="doChange"
        >
        </el-date-picker>
      </div>
    </div>
    <el-table
      v-loading="loading"
      :data="tableData"
      border
      stripe
      highlight-current-row
      :header-row-style="TableHeaderRowStyle"
      :header-cell-style="TableHeaderCellStyle"
      :row-style="TableRowStyle"
      :cell-style="TableCellStyle"
      style="width: 100%"
    >
      <el-table-column type="index" width="45"> </el-table-column>
      <el-table-column v-for="item in labels" :key="item" :label="item" :prop="item" :width="item === '库房' ? 95 : item === '小计' ? 80 : 75">
      </el-table-column>
    </el-table>
    <div class="footer">
      <span style="font-weight:bold;font-size: 20px;"
        >本期钢板桩数量: （库房）{{ sumKucun.zong_kucun }} + （工地）{{ sumKucun.zong_ingongdi_amount }} + （路上）{{ sumKucun.zong_onroad_amount }} =
        {{ totalKucun }}
      </span>
    </div>
  </el-card>
</template>

<script>
import utils from '@/assets/js/utils'
export default {
  data() {
    return {
      title: '',
      footer: '页脚',
      json_fields: {
        库房: '库房',
        '575-6米': '575-6米',
        '575-9米': '575-9米',
        '575-12米': '575-12米',
        '575-15米': '575-15米',
        '400-9米': '400-9米',
        'L400-6米': 'L400-6米',
        'L400-9米': 'L400-9米',
        'L400-12米': 'L400-12米',
        '角板575-6米': '角板575-6米',
        '角板575-9米': '角板575-9米',
        '角板575-12米': '角板575-12米',
        '角板L400-12米': '角板L400-12米',
        '角板L400-9米': '角板L400-9米',
        '角板L400-6米': '角板L400-6米',
        '角板400-9米': '角板400-9米',
        '角板400-12米': '角板400-12米',
        '斜板575-6米': '斜板575-6米',
        '斜板575-9米': '斜板575-9米',
        '斜板575-12米': '斜板575-12米',
        '牙条角板L400-9米': '牙条角板L400-9米',
        '牙条角板L400-12米': '牙条角板L400-12米',
        '牙条角板L400-6米': '牙条角板L400-6米',
        '牙条角板575-6米': '牙条角板575-6米',
        '牙条角板575-9米': '牙条角板575-9米',
        小计: '小计'
      },
      thedate: utils.getDate(),
      tableData: [],
      sumKucun: {},
      labels: [],
      loading: false,
      pickerOptions: {
        // 限制只能选择今天之前的日期
        disabledDate(time) {
          return time.getTime() > Date.now()
        }
      },
      TableHeaderRowStyle() {
        return 'height:35px'
      },
      TableHeaderCellStyle() {
        return 'padding-bottom:4px;padding-top:4px;font-size:15px;font-weight:700;color:#000;'
      },
      TableRowStyle() {
        return 'height:52px'
      },
      TableCellStyle() {
        return 'padding-bottom:4px;padding-top:4px;font-size:18px;color:#000;height:52px'
      }
    }
  },
  computed: {
    totalKucun: function() {
      return Number(this.sumKucun.zong_kucun) + Number(this.sumKucun.zong_ingongdi_amount) + Number(this.sumKucun.zong_onroad_amount)
    }
  },
  methods: {
    getTableData() {
      this.loading = true
      this.$axios
        .get('/kucunBaobiao/', {
          params: {
            date: this.thedate
          }
        })
        .then(res => {
          this.tableData = res.data.tableData
          this.sumKucun = res.data.sumKucun
          this.labels = res.data.labels
          this.loading = false
          console.log('len of labels:', this.labels.length)
          console.log('sumKucun:', this.sumKucun)
          this.title = this.thedate + '各分公司库房库存报表'
          this.footer =
            '本期钢板桩数量: （库房） ' +
            this.sumKucun.zong_kucun +
            '+（工地） ' +
            this.sumKucun.zong_ingongdi_amount +
            '+（路上） ' +
            this.sumKucun.zong_onroad_amount +
            '= ' +
            this.totalKucun
        })
        .catch(function(error) {
          console.log(error)
        })
    },
    doChange() {
      this.getTableData()
    },
    printContent() {
      utils.printContent('printContent')
    }
  },
  created() {
    this.getTableData()
  }
}
</script>

<style scoped>
.footer {
  margin-top: 10px;
  margin-left: 50px;
}
::v-deep .el-table .el-table__footer-wrapper .cell {
  font-weight: 600;
  font-size: 18px;
  color: blue;
}

::v-deep .el-table td,
::v-deep .el-table th.is-leaf,
::v-deep .el-table--border,
::v-deep .el-table--group {
  border-color: black;
}
::v-deep .el-table--border::after,
::v-deep .el-table--group::after,
::v-deep .el-table::before {
  background-color: black;
}
</style>

<style>
.el-table .cell {
  white-space: pre-line;
  text-align: center;
}
</style>
